/* eslint-disable sonarjs/no-identical-functions */
import { formatToYMD, isValidJpDateString } from 'datetime'
import { TranslationFunction } from 'typ'
import * as Yup from 'yup'
import { CAPEX_MAX_DATE, SPV_FINANCIALS_MAX_DATE, SPV_FINANCIALS_MIN_DATE } from './parameters'

type DateValidationOptions = {
  minDate?: Date
  maxDate?: Date
  minDateMsg?: string
  maxDateMsg?: string
  invalidDateMsg?: string
}

const MAX_DECIMALS = 'maxDigitsAfterDecimal'
const NUMBER_INPUT_LENGTH = 'numberInputLength'
export const d = {
  // VALIDATION
  valPassword: 'validation:password',
  valPasswordConfirm: 'validation:password-confirm',
  valNumberTooLarge: 'validation:number-too-large',
  valMinChar: 'validation:min-char',
  valCognitoCodeCharnum: 'auth:cognito-code-charnum',
  valMaxChar: 'validation:max-char',
  valUsername: 'validation:username',
  valRequired: 'validation:required',
  valFloatOnly: 'validation:float-only',
  valDate: 'validation:date',
  valMinDate: 'validation:min-date',
  valMaxDate: 'validation:max-date',
  valInvalidDate: 'validation:invalid-date',
  valPositiveInt: 'validation:positive-int',
  valPositiveFloat: 'validation:positive-float',
  valMaxDecimals: 'validation:max-decimals',
  valTilt: 'validation:tilt',
  valAzimuth: 'validation:azimuth',
  valZeroToX: 'validation:zero-to-x',
  valXToX: 'validation:x-to-x',
  valMax: 'validation:max',
  valMin: 'validation:min',
  valMaxAssetCopies: 'validation:max-asset-copies',
  valAcDc: 'validation:ac-capacity-vs-dc-capacity',
  valLat: 'validation:latitude',
  valLon: 'validation:longitude',
  valInJp: 'validation:within-japan',
  valFitInPpa: 'validation:fit-in-ppa',
  valBeforeYear: 'validation:end-before-2066',
  valDayOfMonth: 'validation:day-of-month',
  valCapex: 'validation:capex-payments',
  valSpvLoans: 'validation:spv-loans',
  valSpvInvestments: 'validation:spv-investments',
  valEmail: 'validation:email',
  invalidNumber: 'validation:invalid-number',
  valOrgId: 'validation:org-id',
  valBgId: 'validation:bg-id-invalid',
  valOCCTOSenderId: 'validation:occto-sender-id-invalid',
  valOCCTOSubmitterId: 'validation:occto-submitter-id-invalid',
  valCompanyRegistrationId: 'validation:company-registration-id',
  valBeforeRepaymentStartDate: 'validation:before-repayment-start-date',
  valAfterRepaymentStartDate: 'validation:after-repayment-start-date',
  valCustomerSupportInput: 'validation:customer-support-input',
}

export const OCCTOGridAreaCode = {
  hokkaido: 1,
  tohoku: 2,
  tokyo: 3,
  chubu: 4,
  hokuriku: 5,
  kansai: 6,
  chugoku: 7,
  shikoku: 8,
  kyushu: 9,
} as const

export type OCCTOGridArea = keyof typeof OCCTOGridAreaCode

const financialsDateBoundaries = {
  minDate: SPV_FINANCIALS_MIN_DATE,
  maxDate: SPV_FINANCIALS_MAX_DATE,
}

const separateIntegerAndDecimal = (value: string | number) => {
  const [itg, dec] = value.toString().replace(/,/g, '').replace('-', '').split('.')
  return [itg ?? '', dec ?? '']
}
const convertFloatValue = (value: string | number) => Number(value?.toString()?.replace(/,/g, ''))

const transformFormattedNumber = (_value: any, originalValue: string | number) => {
  // THIS IS A HACK ONLY TO BE USED WHEN TESTING OPEN ENDED DECIMALS
  // THAT BEACUSE OF FREE INPUT MAY HAVE TOO HIGH OF A PRECISION
  // SO THAT JAVASCRIPT NUMBER CONVERSION WOULD NOT HANDLE THAT PROPERLY
  const [integerPart, decimalPart] = separateIntegerAndDecimal(originalValue)
  if (integerPart.length + decimalPart.length > 16) {
    // RETURN TOO LARGE OF A NUMBER AND FAIL VALIDATION
    return Number.MAX_SAFE_INTEGER * 2
  }
  return convertFloatValue(originalValue)
}

const transformFormattedDecimalNumber = (_value: any, originalValue: string | number) => {
  // THIS IS A HACK ONLY TO BE USED WHEN TESTING OPEN ENDED DECIMALS
  // THAT BEACUSE OF FREE INPUT MAY HAVE TOO HIGH OF A PRECISION
  // SO THAT JAVASCRIPT NUMBER CONVERSION WOULD NOT HANDLE THAT PROPERLY
  const convertedValue = convertFloatValue(originalValue)
  const [integerPart, decimalPart] = separateIntegerAndDecimal(originalValue)
  if (decimalPart.length > 8 && Number.isInteger(convertedValue)) {
    // RETURN TOO MANY DECIMALS AND FAIL VALIDATION
    return 0.0000000001
  }
  if (integerPart.length + decimalPart.length > 16) {
    // RETURN TOO LARGE OF A NUMBER AND FAIL VALIDATION
    return Number.MAX_SAFE_INTEGER * 2
  }
  return convertedValue
}

const upToXDecimals = (number: number | undefined, precision = 4) => {
  if (Number.isInteger(number ?? 0)) return true
  return (number ?? 0).toString().split('.')[1]?.length <= precision
}

export const validateFloat = (t: TranslationFunction) =>
  Yup.number()
    .transform(transformFormattedNumber)
    .test(
      NUMBER_INPUT_LENGTH,
      t(d.valNumberTooLarge),
      (val) => !val || Math.abs(val) <= Number.MAX_SAFE_INTEGER
    )
    .typeError(t(d.invalidNumber))

export const validateMaxDecimalFloat = (
  t: TranslationFunction,
  precision: 1 | 2 | 3 | 4 | 5 | 6 = 4
) =>
  Yup.number()
    .transform(transformFormattedDecimalNumber)
    .test(
      NUMBER_INPUT_LENGTH,
      t(d.valNumberTooLarge),
      (val) => !val || Math.abs(val) <= Number.MAX_SAFE_INTEGER
    )
    .typeError(t(d.invalidNumber))
    .test(MAX_DECIMALS, t(d.valMaxDecimals, { count: precision }), (val: number | undefined) =>
      upToXDecimals(val, precision)
    )
export const optionalString = Yup.string()

export const validateString = () => Yup.string().trim()

export const validateCognitoConfirmationCode = (t: TranslationFunction) =>
  Yup.string().length(6, t(d.valCognitoCodeCharnum))

export const validateName = (t: TranslationFunction) =>
  Yup.string()
    .trim()
    .min(2, t(d.valMinChar, { count: 2 }))
    .max(30, t(d.valMaxChar, { count: 30 }))

export const validateOrgId = (t: TranslationFunction) =>
  Yup.string()
    .trim()
    .length(3, t(d.valOrgId))
    .matches(/^[a-zA-Z]+$/, t(d.valOrgId))

export const validateEntityName = (t: TranslationFunction) =>
  Yup.string()
    .trim()
    .max(50, t(d.valMaxChar, { count: 50 }))

export const validateStakeholdersName = (t: TranslationFunction) =>
  Yup.string()
    .trim()
    .max(100, t(d.valMaxChar, { count: 100 }))

export const validateCustomerSupportInput = (t: TranslationFunction) =>
  Yup.string()
    .required(t(d.valRequired))
    .trim()
    .min(20, t(d.valCustomerSupportInput, { count: 20 }))

export const validateZeroToHundred = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 100 }))
    .max(100, t(d.valZeroToX, { count: 100 }))

export const validateZeroToHundredNoDefault = (t: TranslationFunction) =>
  Yup.string().matches(
    /^(\+)?(?:100(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9])(?:(?:\.[0-9]{1,4})?))$/,
    t(d.valZeroToX, { count: 100 })
  )

export const validateZeroToSixtyFive = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 65 }))
    .max(65, t(d.valZeroToX, { count: 65 }))

export const validatePointZeroOneToHundred = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0.01, t(d.valXToX, { min: 0.01, max: 100 }))
    .max(100, t(d.valXToX, { min: 0.01, max: 100 }))

export const validateMinusPlusZeroToNinetyNine = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(-99, t(d.valXToX, { min: -99, max: 99 }))
    .max(99, t(d.valXToX, { min: -99, max: 99 }))

export const validatePositiveFloat = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t).min(0, t(d.valPositiveFloat))

export const validateIntGreaterThanZero = (t: TranslationFunction) =>
  validateFloat(t)
    .positive(t(d.valPositiveInt))
    .integer(t(d.valPositiveInt))
    .min(1, t(d.valPositiveInt))

export const validateMaxAssetCopies = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(1, t(d.valMin, { count: 1 }))
    .integer(t(d.valPositiveInt))
    .max(20, t(d.valMaxAssetCopies))

export const validateDate = (t: TranslationFunction) => Yup.date().typeError(t(d.valDate))

export const validateJpDateString = (
  t: TranslationFunction,
  options: DateValidationOptions = {}
  // eslint-disable-next-line sonarjs/cognitive-complexity
) =>
  Yup.string()
    .test('is-valid-date', options?.invalidDateMsg ?? t(d.valInvalidDate), (value) =>
      Boolean(!value || isValidJpDateString(String(value)))
    )
    .test(
      'is-within-min-date',
      options.minDate
        ? options.minDateMsg ?? t(d.valMinDate, { date: formatToYMD(options.minDate) })
        : '',
      (value) => {
        if (!value) return true
        if (!value || !isValidJpDateString(String(value))) return false
        const date = new Date(value).setHours(0, 0, 0, 0)
        const minDate = options.minDate?.setHours(0, 0, 0, 0)
        return !(minDate && date < minDate)
      }
    )
    .test(
      'is-within-max-date',
      options.maxDate
        ? options.maxDateMsg ?? t(d.valMaxDate, { date: formatToYMD(options.maxDate) })
        : '',
      (value) => {
        if (!value) return true
        if (!value || !isValidJpDateString(String(value))) return false
        const date = new Date(value).setHours(0, 0, 0, 0)
        const maxDate = options.maxDate?.setHours(0, 0, 0, 0)
        return !(maxDate && date > maxDate)
      }
    )
    .required(t(d.valRequired))

export const validateMonthDate = (t: TranslationFunction) =>
  validateFloat(t)
    .integer(t(d.valDayOfMonth))
    .min(1, t(d.valDayOfMonth))
    .max(31, t(d.valDayOfMonth))

export const validateCapexAmount = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .positive(t(d.valPositiveInt))
    .min(1, t(d.valMin, { count: 1 }))
    .max(10 ** 11, t(d.valMax, { count: '100,000,000,000' }))

export const validateElectricityPrice = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valMin, { count: '0' }))
    .max(10 ** 4, t(d.valMax, { count: '10,000' }))

export const validatePriceForecastValue = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 2)
    .min(0.01, t(d.valMin, { count: '0.01' }))
    .max(200, t(d.valMax, { count: '200' }))

export const validateFloorPrice = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(-1000, t(d.valMin, { count: '-1000' }))
    .max(1000, t(d.valMax, { count: '1000' }))

export const validateAnnualDegradation = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: '10' }))
    .max(10, t(d.valZeroToX, { count: '10' }))
export const validateLosses = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 50 }))
    .max(50, t(d.valZeroToX, { count: 50 }))
export const validateTotalLosses = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 2)
    .min(0, t(d.valZeroToX, { count: 93.75 }))
    .max(93.75, t(d.valZeroToX, { count: 93.75 }))
// eslint-disable-next-line sonarjs/no-identical-functions
export const validateTaxRate = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 50 }))
    .max(50, t(d.valZeroToX, { count: 50 }))
// ASSUMPTIONS
export const validateCurtailmentAssumption = (t: TranslationFunction) => validateZeroToSixtyFive(t)
export const validateMapeAssumption = (t: TranslationFunction) => validateZeroToHundred(t)
export const validateInflationAssumption = (t: TranslationFunction) =>
  validateMinusPlusZeroToNinetyNine(t)
export const validatePriceAssumption = (t: TranslationFunction) => validatePointZeroOneToHundred(t)
// ASSETS
export const validateLatitude = (t: TranslationFunction) =>
  Yup.string().matches(
    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    t(d.valLat)
  )
export const validateIsolatedLatitude = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 6).min(-60, t(d.valLat)).max(60, t(d.valLat))

export const validateIsolatedLongitude = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 6).min(80, t(d.valLon)).max(180, t(d.valLon))

export const validateLongitude = (t: TranslationFunction) =>
  Yup.string().matches(
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    t(d.valLon)
  )

export const validateTilt = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 6).min(0, t(d.valTilt)).max(90, t(d.valTilt))

export const validateAzimuth = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t, 6).min(-360, t(d.valAzimuth)).max(360, t(d.valAzimuth))

export const validateAltitude = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(-100, t(d.valMin, { count: '-100' }))
    .max(8000, t(d.valMax, { count: '8000' }))

export const getCapexPaymentsValidationObject = (t: TranslationFunction) => ({
  date: validateJpDateString(t, {
    minDate: SPV_FINANCIALS_MIN_DATE,
    maxDate: CAPEX_MAX_DATE,
  }).required(t(d.valRequired)),
  amount: validateCapexAmount(t).required(t(d.valRequired)),
  category: validateString().required(t(d.valRequired)),
  description: Yup.string(),
  consumptionTax: Yup.number().required(t(d.valRequired)),
})

export const validateCapexPayments = (t: TranslationFunction) =>
  Yup.array(Yup.object(getCapexPaymentsValidationObject(t))).typeError(t(d.valCapex))
// AUTH
export const validateUsername = (t: TranslationFunction) =>
  Yup.string().matches(/^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/, t(d.valUsername))

export const validateEmail = (t: TranslationFunction) => Yup.string().trim().email(t(d.valEmail))

export const validatePassword = (t: TranslationFunction) =>
  Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t(d.valPassword))

export const validateConfirmPassword = (t: TranslationFunction, passwordKey = 'password') =>
  Yup.string().oneOf([Yup.ref(passwordKey), null], t(d.valPasswordConfirm))

// SETTINGS
export const validateOCCTOSenderId = (t: TranslationFunction) =>
  Yup.string().matches(/^[a-zA-Z0-9]{4}$/, t(d.valOCCTOSenderId))

export const validateOCCTOSubmitterId = (t: TranslationFunction) =>
  Yup.string()
    .required(t(d.valRequired))
    .length(5, t(d.valOCCTOSubmitterId))
    .when('area', {
      is: (val: string) => val,
      then: (schema) =>
        schema.test(
          'grid-area-submitter-id-matcher',
          () => t(d.valOCCTOSubmitterId),
          (value, { parent: { area } }) =>
            new RegExp(
              `^[DG][0-9][0-9A-Z][0-9A-Z]${OCCTOGridAreaCode?.[area as OCCTOGridArea]}$`
            ).test(value ?? '')
        ),
    })

export const validateBgCode = (t: TranslationFunction) =>
  Yup.string()
    .length(5, t(d.valBgId))
    .when('gridZone', {
      is: (val: string) => val,
      then: (schema) =>
        schema.test(
          'bg-code-matcher',
          () => t(d.valBgId),
          (value, { parent: { gridZone } }) =>
            new RegExp(
              `^[DGL][A-HJ-NP-Z][0-9A-HJ-NP-Z]{2}${OCCTOGridAreaCode?.[gridZone.toLowerCase() as OCCTOGridArea]}$`
            ).test(value ?? '')
        ),
    })

// SPVS
export const validateTargetDSCR = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(1, t(d.valXToX, { min: 1, max: 5 }))
    .max(5, t(d.valXToX, { min: 1, max: 5 }))

export const validateSpvFinancialsValue = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .positive(t(d.valPositiveInt))
    .min(1, t(d.valMin, { count: 1 }))
    .max(10 ** 12, t(d.valMax, { count: '1,000,000,000,000' }))

// eslint-disable-next-line sonarjs/no-identical-functions
export const validateSpvFinancialsString = (t: TranslationFunction) =>
  Yup.string()
    .trim()
    .max(100, t(d.valMaxChar, { count: 100 }))

export const getSpvLoanLineValidationObject = (t: TranslationFunction) => ({
  amount: validateSpvFinancialsValue(t).required(t(d.valRequired)),
  description: validateSpvFinancialsString(t).required(t(d.valRequired)),
  interestRate: validateZeroToHundredNoDefault(t).required(t(d.valRequired)),
  lenderName: validateSpvFinancialsString(t).required(t(d.valRequired)),
  loanReceivedDate: validateJpDateString(t, financialsDateBoundaries)
    .required(t(d.valRequired))
    .when('repaymentStartDate', {
      is: (val: string) => !!val,
      then: validateJpDateString(t, financialsDateBoundaries)
        .test(
          'Before or equal to repayment start date',
          () => t(d.valBeforeRepaymentStartDate),
          (value, { parent }) =>
            new Date(value ?? 0).setHours(0, 0, 0, 0) <=
            new Date(parent.repaymentStartDate).setHours(0, 0, 0, 0)
        )
        .required(t(d.valRequired)),
    }),
  repaymentStartDate: validateJpDateString(t, financialsDateBoundaries).required(t(d.valRequired)),
  repaymentEndDate: validateJpDateString(t, financialsDateBoundaries)
    .required(t(d.valRequired))
    .when('repaymentStartDate', {
      is: (val: string) => !!val,
      then: validateJpDateString(t, financialsDateBoundaries)
        .test(
          'After repayment start date',
          () => t(d.valAfterRepaymentStartDate),
          (value, { parent }) =>
            new Date(value ?? 0).setHours(0, 0, 0, 0) >
            new Date(parent.repaymentStartDate).setHours(0, 0, 0, 0)
        )
        .required(t(d.valRequired)),
    }),
  repaymentFrequency: validateString().required(t(d.valRequired)),
})

export const validateSpvLoanLine = (t: TranslationFunction) =>
  Yup.object().shape(getSpvLoanLineValidationObject(t), [
    ['repaymentStartDate', 'repaymentEndDate'],
    ['repaymentStartDate', 'loanReceivedDate'],
  ])

export const validateSpvLoans = (t: TranslationFunction) =>
  Yup.array(
    Yup.object().shape(getSpvLoanLineValidationObject(t), [
      ['repaymentStartDate', 'repaymentEndDate'],
      ['repaymentStartDate', 'loanReceivedDate'],
    ])
  ).typeError(t(d.valSpvLoans))

export const getSpvInvestmentLineValidationObject = (t: TranslationFunction) => ({
  investorName: validateSpvFinancialsString(t).required(t(d.valRequired)),
  amount: validateSpvFinancialsValue(t).required(t(d.valRequired)),
  investmentDate: validateJpDateString(t, financialsDateBoundaries).required(t(d.valRequired)),
})
export const validateSpvInvestmentLine = (t: TranslationFunction) =>
  Yup.object(getSpvInvestmentLineValidationObject(t))

export const validateSpvInvestments = (t: TranslationFunction) =>
  Yup.array(Yup.object(getSpvInvestmentLineValidationObject(t))).typeError(t(d.valSpvInvestments))

export const validateCompanyRegistrationId = (t: TranslationFunction) =>
  Yup.string()
    .transform((val) => val.replace(/-/g, ''))
    .matches(/^\d{13}$/, t(d.valCompanyRegistrationId))

export const validateBatteryCycleCost = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 10 ** 5 }))
    .max(10 ** 5, t(d.valZeroToX, { count: 10 ** 5 }))

export const validateBatteryImbalanceValue = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 9999 }))
    .max(9999, t(d.valZeroToX, { count: 9999 }))

export const validateBatteryCapacity = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0.1, t(d.valXToX, { min: 0.1, max: 10 ** 7 }))
    .max(10 ** 7, t(d.valXToX, { min: 0.1, max: 10 ** 7 }))

export const validateBatteryExpectedCycleLife = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(1, t(d.valXToX, { min: 1, max: 10 ** 5 }))
    .max(10 ** 5, t(d.valXToX, { min: 1, max: 10 ** 5 }))

export const validateBatteryMaximumStateOfCharge = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(50, t(d.valXToX, { min: 50, max: 100 }))
    .max(100, t(d.valXToX, { min: 50, max: 100 }))

export const validateBatteryMinimumStateOfCharge = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 50 }))
    .max(50, t(d.valZeroToX, { count: 50 }))

export const validateBatteryRoundtripEfficiency = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(50, t(d.valXToX, { min: 50, max: 100 }))
    .max(100, t(d.valXToX, { min: 50, max: 100 }))

export const validateBatteryWarrantyPeriod = (t: TranslationFunction) =>
  validateFloat(t)
    .positive(t(d.valPositiveInt))
    .integer(t(d.valPositiveInt))
    .min(1, t(d.valXToX, { min: 1, max: 50 }))
    .max(50, t(d.valXToX, { min: 1, max: 50 }))

export const validateLoadEntityLoad = (t: TranslationFunction) =>
  validateMaxDecimalFloat(t)
    .min(0, t(d.valZeroToX, { count: 10 ** 5 }))
    .max(10 ** 5, t(d.valZeroToX, { count: 10 ** 5 }))

// TRADING
// export const getCustomTradeValidationObject = (t: TranslationFunction) => ({
//   bidVolume: validateMaxDecimalFloat(t)
//     .positive(t(d.valPositiveInt))
//     .min(0, t(d.valMin, { count: 0 }))
//     .max(10 ** 6, t(d.valMax, { count: '1,000,000' }))
//     .required(t(d.valRequired)),
//   bidPrice: validatePriceForecastValue(t).required(t(d.valRequired)),
// })
